import React from 'react';

type ContainerProps = {
  children: React.ReactNode;
  className?: string;
};

const Container = ({ children, className }: ContainerProps) => (
  <div className={`mx-auto max-w-[1440px] px-4 sm:px-10 lg:px-20 ${className}`}>
    {children}
  </div>
);

export default Container;
