import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Header from '@/components/common/v4/header';
import Footer from '@/components/common/v5/footer';
import { responsiveMediaStyles } from '@/utils/responsive-media';
import { useStaticQuery, graphql } from 'gatsby';
import InfoBar from '@/components/info-bar';
import { useStore } from '@/store';
import { useReplaceLinks } from '@/hooks/useReplaceLinks';
import { enableVisualEditing } from '@sanity/visual-editing';

type layoutProps = {
  children?: React.ReactNode;
  title?: string;
  description?: string;
  image?: string;
  imageWidth?: string;
  imageHeight?: string;
  mainContentVariant?: 'gray' | 'dark';
};

const Layout = ({ children, mainContentVariant = 'dark' }: layoutProps) => {
  useReplaceLinks();
  const query = useStaticQuery(graphql`
    {
      sanitySiteSettings {
        description
        siteName
        siteUrl
        infoBar {
          infoBarCtaText
          infoBarCtaUrl
          showInfoBar
          countDown
          _rawText(resolveReferences: { maxDepth: 10 })
        }
      }
    }
  `);
  const data = query.sanitySiteSettings;
  const { showInfoBar } = data.infoBar;
  const { closeInfoBar } = useStore();

  const isInfoBarVisible = showInfoBar && !closeInfoBar;

  const bgColor = {
    dark: 'bg-smoky-black',
    gray: 'bg-[#f6f7ff]',
  };

  useEffect(() => {
    const subscribe = enableVisualEditing({
      zIndex: 1000,
    });
    return () => subscribe();
  }, []);

  return (
    <>
      <Helmet>
        <html lang="en" />
        <meta charSet="utf-8" />
        <style>{responsiveMediaStyles}</style>
        <script
          async
          src="https://tag.clearbitscripts.com/v1/pk_9b17e3cbc6a1dfd3c88f42273c421a6f/tags.js"
          referrerPolicy="strict-origin-when-cross-origin"
        ></script>
        <script
          type="text/javascript"
          async
          src="https://play.vidyard.com/embed/v4.js"
        ></script>
      </Helmet>
      <div
        className={`flex min-h-full flex-col ${bgColor[mainContentVariant]} ${
          isInfoBarVisible ? 'pt-[104px] lg:pt-[7.75rem]' : 'pt-5 lg:pt-[80px]'
        }`}
      >
        {isInfoBarVisible && <InfoBar infoBar={data.infoBar} />}

        <Header showInfoBar={isInfoBarVisible} />
        <main className="grow overflow-hidden">{children}</main>
        <div className={`${bgColor.dark} z-10`}>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Layout;
