import React from 'react';
import { useSiteMetadata } from '../hooks/use-site-metadata';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';

const SEO = ({
  title,
  description,
  children,
  image,
  imageWidth = 1200,
  imageHeight = 630,
  type,
  isBlogPost,
  author,
  authors,
  datePublished,
}: {
  title?: string;
  description?: string;
  children?: React.ReactNode;
  image?: string; // make optional
  imageWidth?: number;
  imageHeight?: number;
  type?: string;
  isBlogPost?: boolean;
  author?: string;
  authors?: {
    name: string;
    role: string;
    image: string;
  }[];
  datePublished?: string;
}) => {
  const { pathname } = useLocation();

  const {
    title: defaultTitle,
    description: defaultDescription,
    image: defaultImage,
    siteUrl,
    twitterUsername,
    siteName,
    icon,
    buildTime,
  } = useSiteMetadata();

  const seo = {
    title: `${title} - ${siteName}` || defaultTitle,
    description: description || defaultDescription,
    image: image ? `${siteUrl}${image}` : `${siteUrl}${defaultImage}`,
    url: `${siteUrl}${pathname}`,
    twitterUsername,
    icon: `${siteUrl}${icon}`,
    type: type || `website`,
    author: author || { name: `${twitterUsername}` },
    authors: authors || { name: `${twitterUsername}` },
    datePublished: buildTime,
  };

  const authorsString = authors?.map((author) => author.name).join(', ');

  return (
    <>
      {/* OpenGraph tags */}
      <meta property="og:url" content={seo.url} />
      <meta property="og:type" content={seo.type} />
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta name="image" property="og:image" content={seo.image} />
      <meta property="og:image:type" content="image/png" />
      <meta property="og:image:width" content={`${Math.round(imageWidth)}`} />
      <meta property="og:image:height" content={`${Math.round(imageHeight)}`} />

      {/* <meta property="fb:app_id" content={seo.social.fbAppID} /> */}
      <meta property="og:site_name" content={siteName}></meta>
      {/* General tags */}
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <link rel="canonical" href={seo.url} />
      <meta name="theme-color" content="#faf0f2"></meta>

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={seo.twitterUsername} />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
      <meta name="twitter:site" content={seo.twitterUsername} />

      {/* Optional Twitter Labels */}
      {isBlogPost && authors && (
        <>
          <meta name="author" content={authorsString}></meta>
          <meta name="twitter:label1" content="Written By" />
          <meta name="twitter:data1" content={authorsString} />
          <meta name="twitter:label2" content="Published By" />
          <meta name="twitter:data2" content={siteName} />
        </>
      )}

      {children}
    </>
  );
};

export default SEO;
